import React from 'react'
import { withIntl } from '../i18n'

import Layout from '../components/Layout'
import Embed from 'react-responsive-embed'


const VideoPage = props => {

  return (
    <Layout>
      <div className="video">
        <Embed src='https://youtube.com/embed/BhYLsgok59g' allowfullscreen />
      </div>
      <div className="video">
        <Embed src='https://youtube.com/embed/wQJzNwgn7eU' allowfullscreen />
      </div>
      <div className="video">
        <Embed src='https://youtube.com/embed/R2ZdjkpvKmA' allowfullscreen />
      </div>

    </Layout>
  )
}

export default withIntl(VideoPage)
